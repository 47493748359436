import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  getActiveOrder,
  subscribeToActiveOrder,
  fetchDistanceInMilesAndDurationInMinutes,
} from "../Firebase/order";
import "./Tracker.css";
import bike from "../Icons/bike.svg";
import box from "../Icons/box.svg";
import dropoff from "../Icons/dropoff.svg";
import bikeBox from "../Icons/bike_box.svg";

const defaultCenter = { lat: 51.509865, lng: -0.118092 };
const googleMapsAPIKey = "AIzaSyDc8ZE2eaxXND5WTbOeZ5BZc008_ykqv2M";

const Tracker = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [distDuration, setDistDuration] = useState({
    distance: 0,
    duration: 0,
    lastFetchTime: 0,
  });
  const mapRef = useRef(null);
  const timerRef = useRef(null);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const getStatusText = (status) => {
    switch (status) {
      case "pending":
        return "Finding a Blitzer";
      case "accepted":
        return "Heading to Pickup";
      case "pickedUp":
        return "En Route to Dropoff";
      case "delivered":
        return "Delivered";
      default:
        return "Loading...";
    }
  };

  const getUpdateFrequency = (duration) => {
    if (duration <= 15 * 60) return 90; // 90 seconds for trips <= 15 minutes
    if (duration <= 30 * 60) return 3 * 60; // 3 minutes for trips <= 30 minutes
    return 5 * 60; // 5 minutes for longer trips
  };

  const fetchDistanceAndDuration = useCallback(async (orderData) => {
    if (
      orderData.assignment &&
      orderData.pickupLocation &&
      orderData.dropoffLocation
    ) {
      let newDistDuration = { distance: 0, duration: 0 };
      if (orderData.status === "accepted") {
        newDistDuration = await fetchDistanceInMilesAndDurationInMinutes(
          orderData.assignment.blitzerLocation,
          orderData.pickupLocation
        );
      } else if (orderData.status === "pickedUp") {
        newDistDuration = await fetchDistanceInMilesAndDurationInMinutes(
          orderData.assignment.blitzerLocation,
          orderData.dropoffLocation
        );
      }
      setDistDuration({
        ...newDistDuration,
        lastFetchTime: Date.now(),
      });
    }
  }, []);

  const updateMapBounds = useCallback((orderData) => {
    if (orderData && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      if (orderData.assignment) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderData.assignment.blitzerLocation.latitude,
            orderData.assignment.blitzerLocation.longitude
          )
        );
      }
      if (orderData.pickupLocation) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderData.pickupLocation.latitude,
            orderData.pickupLocation.longitude
          )
        );
      }
      if (orderData.dropoffLocation) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderData.dropoffLocation.latitude,
            orderData.dropoffLocation.longitude
          )
        );
      }
      mapRef.current.fitBounds(bounds);
    }
  }, []);

  useEffect(() => {
    let unsubscribe;

    const fetchOrderAndSubscribe = async () => {
      const orderData = await getActiveOrder(orderId);
      setOrder(orderData);

      unsubscribe = subscribeToActiveOrder(orderId, (updatedOrderData) => {
        setOrder(updatedOrderData);
      });
    };

    fetchOrderAndSubscribe();

    return () => {
      if (unsubscribe) unsubscribe();
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [orderId]);

  useEffect(() => {
    if (order) {
      const currentTime = Date.now();
      const timeSinceLastFetch =
        (currentTime - distDuration.lastFetchTime) / 1000;
      const updateFrequency = getUpdateFrequency(distDuration.duration);

      if (
        timeSinceLastFetch >= updateFrequency ||
        order.status !== distDuration.lastStatus
      ) {
        fetchDistanceAndDuration(order);
      }

      updateMapBounds(order);

      // Clear existing timer
      if (timerRef.current) clearInterval(timerRef.current);

      // Set up new timer for ETA updates
      timerRef.current = setInterval(() => {
        setDistDuration((prev) => ({
          ...prev,
          duration: Math.max(0, prev.duration - 60), // Decrease by 1 minute (60 seconds)
        }));
      }, 60000); // Update every minute
    }

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [order, fetchDistanceAndDuration, updateMapBounds]);

  const riderLocation = order?.assignment?.blitzerLocation;
  const pickupLocation = order?.pickupLocation;
  const dropoffLocation = order?.dropoffLocation;

  const getEtaText = () => {
    if (distDuration.duration === 0) return "-";
    const minutes = Math.floor(distDuration.duration / 60);
    if (order?.status === "accepted") {
      return `${minutes} minutes to pickup`;
    } else if (order?.status === "pickedUp") {
      return `${minutes} minutes to delivery`;
    }
    return `${minutes} minutes`;
  };

  return (
    <div className="tracker-container">
      <LoadScript googleMapsApiKey={googleMapsAPIKey}>
        <GoogleMap
          options={{
            styles: mapStyle,
            mapTypeControlOptions: { mapTypeIds: [] },
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
          }}
          onLoad={onLoad}
          mapContainerStyle={{
            height: "100vh",
            width: "100vw",
          }}
          center={mapCenter}
        >
          {riderLocation && (
            <Marker
              position={{
                lat: riderLocation.latitude,
                lng: riderLocation.longitude,
              }}
              icon={{
                url: order.status === "accepted" ? bike : bikeBox,
                scaledSize: new window.google.maps.Size(50, 50),
              }}
            />
          )}
          {pickupLocation && order.status !== "pickedUp" && (
            <Marker
              position={{
                lat: pickupLocation.latitude,
                lng: pickupLocation.longitude,
              }}
              icon={{
                url: box,
                scaledSize: new window.google.maps.Size(50, 50),
              }}
            />
          )}
          {dropoffLocation && (
            <Marker
              position={{
                lat: dropoffLocation.latitude,
                lng: dropoffLocation.longitude,
              }}
              icon={{
                url: dropoff,
                scaledSize: new window.google.maps.Size(50, 50),
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
      <div className="info-overlay">
        <div className="info-card order-id">
          <h2>Order ID</h2>
          <p>{orderId}</p>
        </div>
        <div className="info-card distance">
          <h2>Distance</h2>
          <p>
            {distDuration.distance === 0
              ? "-"
              : `${distDuration.distance.toFixed(2)} miles`}
          </p>
        </div>
        <div className="info-card eta">
          <h2>ETA</h2>
          <p>{getEtaText()}</p>
        </div>
        <div className="info-card status">
          <h2>Status</h2>
          <p>{order?.status ? getStatusText(order.status) : "Loading..."}</p>
        </div>
      </div>
    </div>
  );
};

export default Tracker;

export const mapStyle = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#686868",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        lightness: "-22",
      },
      {
        visibility: "on",
      },
      {
        color: "#b4b4b4",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        saturation: "-51",
      },
      {
        lightness: "11",
      },
    ],
  },
];
